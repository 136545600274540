<script lang="ts">
	import { page } from '$app/stores';
</script>

<nav>
	<ul>
		<li aria-current={$page.url.pathname === '/forum' ? 'page' : undefined}>
			<a href="/forum">Forum</a>
		</li>
		<li aria-current={$page.url.pathname === '/tournaments' ? 'page' : undefined}>
			<!-- <a href="/tournaments">Turneringar</a> -->
			<span>Turneringar</span>
		</li>
		<li aria-current={$page.url.pathname.startsWith('/servers') ? 'page' : undefined}>
			<a href="/servers">Servrar</a>
		</li>
		<li aria-current={$page.url.pathname === '/scrim' ? 'page' : undefined}>
			<!-- <a href="/scrim">Scrim</a> -->
			<span>Scrim</span>
		</li>
	</ul>
</nav>

<style lang="scss">
	nav {
		position: relative;
		margin-left: 32px;
		width: auto;
	}

	ul {
		position: relative;
		padding: 0;
		margin: 0;
		height: 3em;
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		background: var(--background);
		background-size: contain;
		gap: 0.5rem;
	}

	li {
		position: relative;
		height: 100%;
	}

	nav a,
	nav span {
		display: flex;
		height: 100%;
		align-items: center;
		padding: 0 1rem;
		font-weight: 700;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-decoration: none;
		transition: all 0.2s ease;
	}

	nav a {
		color: var(--color-text);
		&:hover {
			color: var(--color-theme-1);
		}
	}

	nav span {
		color: rgb(75, 75, 75);
		cursor: not-allowed;
	}

	@media (max-width: 768px) {
		nav {
			margin-left: 0;
			width: 100%;

			ul {
				height: auto;
				flex-direction: column;
				align-items: stretch;
				gap: 1px;
				background: #131418;
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
			}

			li {
				width: 100%;
				height: auto;
				background: #0a0a0c;

				&:first-child {
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
				}

				&:last-child {
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}

				&:hover {
					background: #131418;
				}
			}

			a,
			span {
				width: 100%;
				padding: 1rem;
				justify-content: flex-start;
			}
		}
	}
</style>
