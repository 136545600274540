<script lang="ts">
	import { user_info } from '$lib/stores/user';
	import { Warning } from 'phosphor-svelte';

	let showWarning = $state(false);

	$effect(() => {
		let incomplete = $user_info?.incomplete_account;
		let active = $user_info?.active;
		let verified = $user_info?.verified;

		if (incomplete || active === false || verified === false) {
			showWarning = true;
		} else {
			showWarning = false;
		}
	});
</script>

{#if showWarning}
	<div class="absolute left-0 z-50 w-full top-10">
		<div
			class="flex items-center p-4 m-4 space-x-4 text-white border-2 border-yellow-500 rounded-md bg-yellow-600/90"
		>
			<Warning class="text-yellow-500" size="2rem" />
			<p>
				Ditt konto har ett eller flera problem som behöver lösas innan kontot kan användas.
				<a class="text-blue-600 underline" href="https://discord.playpark.se"
					>Kontakta oss för hjälp.</a
				>
			</p>
		</div>
	</div>
{/if}
